"use strict";

import onInitModulesInsScope from "./onInitModulesInScope";

export function init() {
    onInitModulesInsScope(function initInScope($scope) {

        let $cookieConsentButtons = $('.js-consent__accept-btn');

        $cookieConsentButtons.each(function(){
            let $cookieConsentButton = $(this);
            let service = $cookieConsentButton.closest('.js-consent').data('consent-service');
            let type = $cookieConsentButton.closest('.js-consent').data('consent-type');
            let overlay = $cookieConsentButton.closest('.js-consent__overlay');
            var wrapper = $cookieConsentButton.closest('.js-consent');

            if (type === 'iframe' || type === 'tour') {
                let wrapper = $cookieConsentButton.closest('.js-consent');
                let iframe = wrapper.find('.js-consent__iframe');
                let src = iframe.data('iframe-src');
                let overlay =$cookieConsentButton.closest('.js-consent__overlay');

                if(getServicePermission(service)){
                    console.log('service permission granted')
                    iframe.prop('src', src);
                    if(overlay.length !== 0){
                        overlay.attr("hidden", "hidden");
                        wrapper.removeClass("consent--box-shadow");
                        wrapper.removeClass("embed-responsiv");
                        wrapper.removeClass("embed-responsive-16by9");
                    }
                }else{
                    console.log('service permission not granted');
                    if(overlay.length !== 0){
                        overlay.attr("hidden", false);
                        wrapper.addClass("consent--box-shadow");
                    }
                    $cookieConsentButton.on('click', function(e){
                        setServicePermission(service);
                        iframe.prop('src', src);
                        if(overlay.length !== 0){
                            overlay.attr("hidden", "hidden");
                            wrapper.removeClass("consent--box-shadow");
                        }
                        if(type === 'tour') {
                            window.addEventListener('CookiebotOnAccept', function (e) {
                                if (overlay.length !== 0) {
                                    overlay.attr("hidden", "hidden");
                                    wrapper.removeClass("consent--box-shadow");
                                    window.location.reload();
                                }
                            });
                        }
                    });


                }
            } else {
                $cookieConsentButton.on('click', function (e) {
                    setServicePermission(service);
                });
            }
        });
        function getServicePermission(service) {
            let consent = service;
            // Parse the consent string into an array if it contains commas
            if (consent.indexOf(',') > -1) {
                consent = consent.split(',');
            }
            // Determine if all consent categories are accepted
            let allConsentsGranted = true;

            if (Array.isArray(consent)) {
                // Iterate through each required consent category
                for (const consentCategory of consent) {
                    if (!Cookiebot.consent[consentCategory]) {
                        allConsentsGranted = false;
                        break;
                    }
                }
            } else {
                allConsentsGranted = !!Cookiebot.consent[consent];
            }

            return allConsentsGranted;
        }
        function setServicePermission(service) {
            let consent = service;
            // check if consent is a string with a comma
            if (consent.indexOf(',') > -1) {
                consent = consent.split(',');
            }
            // check if consent is an array
            if (Array.isArray(consent)) {
                let preferences = consent.indexOf('preferences') > -1;
                let statistics = consent.indexOf('statistics') > -1;
                let marketing = consent.indexOf('marketing') > -1;

                Cookiebot.submitCustomConsent(preferences, statistics, marketing);
            } else {
                let preferences = consent === 'preferences';
                let statistics = consent === 'statistics';
                let marketing = consent === 'marketing';

                Cookiebot.submitCustomConsent(preferences, statistics, marketing);
            }
            // app.initModules($cookieConsentButtons);
        }
    })
}

